<template>
  <div>
    <!-- fixed top header -->
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button v-if="activeStep > 0" type="warning" @click="prevStep" icon="el-icon-arrow-left">Өмнөх</el-button>
          <el-button v-if="activeStep < 2" :disabled="isButtonDisabled" :type="isButtonDisabled ? 'disabled' : 'primary'" :loading="isButtonLoading" @click="nextStep">Дараах <i class="el-icon-arrow-right el-icon-right"></i></el-button>
          <el-button v-if="activeStep===2" :disabled="isDisabledCart" :type="isDisabledCart ? 'disabled' : 'success'" :loading="isButtonLoading" @click="saveOrder('orderForm')">Захиалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-form label-position="top" :model="orderForm" ref="orderForm" :rules="orderFormRules">
      <el-row>
        <el-col :span="20" :offset="2">
          <custom-header title="Захиалга үүсгэх" :isBackable="false"/>
          <el-steps :space="200" :active="activeStep" finish-status="success" align-center  style="justify-content: center" class="mb20">
            <el-step title="Захиалагч"></el-step>
            <el-step title="Хүргэлтийн хаяг"></el-step>
            <el-step title="Бүтээгдэхүүн"></el-step>
          </el-steps>
          <el-row :gutter="20">
            <el-col :span="activeStep === 0 ? 24 : 16">
              <div class="panel" v-if="activeStep === 0">
                <div class="panel-item">
                  <header>Захиалагч</header>
                  <el-form-item label="Утас" prop="phone">
                    <el-input v-model="orderForm.phone" type="number"></el-input>
                  </el-form-item>
                  <el-form-item label="Email" prop="email">
                    <el-input v-model="orderForm.email" type="email"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="panel" v-if="activeStep === 1">
                <div class="panel-item">
                  <header>Хүргэлтийн хаяг</header>
                  <el-form-item label="Хаяг" prop="address.full_address">
                    <el-select
                      style="width: 100%"
                      v-model="orderForm.address.full_address"
                      filterable
                      remote
                      @change="onChangeAddress"
                      placeholder="ХУД 3-р хороо Гоёо"
                      :remote-method="filterAddress"
                      :loading="addressLoading">
                      <el-option
                        v-for="item in suggestedAddresses"
                        :key="item.full_address"
                        :label="item.full_address"
                        :value="item.full_address">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Орц" prop="address.orts">
                    <el-input v-model="orderForm.address.orts"></el-input>
                  </el-form-item>
                  <el-form-item label="Хаалга" prop="address.haalga">
                    <el-input v-model="orderForm.address.haalga"></el-input>
                  </el-form-item>
                  <el-form-item label="Орцны код">
                    <el-input v-model="orderForm.address.orts_code"></el-input>
                  </el-form-item>
                  <el-form-item label="Нэмэлт">
                    <el-input v-model="orderForm.address.desc" type="textarea" :rows="3"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="panel" v-if="activeStep === 2">
                <div class="panel-item">
                  <header>Бүтээгдэхүүн</header>
                  <el-input placeholder="Бүтээгдэхүүн хайх" class="input-with-select" v-model="searchText" @input="searchProduct">
                    <el-button
                      slot="prepend"
                      icon="el-icon-search"
                      @click="searchProduct"
                    ></el-button>
                  </el-input>
                </div>
              </div>
              <div class="panel" v-if="activeStep === 2">
                <div class="panel-item">
                  <header>Сагс</header>
                  <ul class="transfer-product-list" v-if="orderForm.products.length > 0">
                    <div v-for="(product) in orderForm.products" :key="product.productId">
                      <el-row :gutter="10" class="mb20" style="background-color: orange; padding: 10px; border-radius: 6px" type="flex" align="middle" v-if="product.is_discount" >
                        <el-col :span="8">
                          <div>
                            {{ product.product_name + " - " + product.variant_name}}
                          </div>
                          <div class="text-color-secondary">
                            {{product.sku}}
                          </div>
                        </el-col>
                        <el-col :span="11">
                          <el-row :gutter="5" type="flex" align="middle">
                            <el-col :span="8" class="text-left"> = {{generateCurrency(product.menu_price * product.item_count)}}</el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="2" class="text-right">
                          <i @click="deleteVariantFromOrder(product)" class="el-icon-close cursor-pointer"></i>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb20" style="padding: 10px" type="flex" align="middle" v-else >
                        <el-col :span="8">
                          <div>
                            {{ product.product_name + " - " + product.variant_name}}
                          </div>
                          <div class="text-color-secondary">
                            {{product.sku}}
                          </div>
                        </el-col>
                        <el-col :span="11">
                          <el-row :gutter="5" type="flex" align="middle">
                            <el-col :span="8" class="text-right">{{generateCurrency(product.menu_price)}} x  </el-col>
                            <el-input
                              style="width:75px"
                              @keyup.enter.native="changeCount"
                              @focus="changeCount"
                              @blur="changeCount"
                              type="number"
                              :min="1"
                              size="mini"
                              v-model="product.item_count"
                            />
                            <el-col :span="8" class="text-left"> = {{generateCurrency(product.menu_price * product.item_count)}}</el-col>
                          </el-row>
                        </el-col>
                        <el-col :span="2" class="text-right">
                          <i @click="deleteVariantFromOrder(product)" class="el-icon-close cursor-pointer"></i>
                        </el-col>
                      </el-row>
                    </div>
                  </ul>
                  <div v-else class="ptb30 text-color-secondary text-center">Бүтээгдэхүүн сонгоно уу</div>
                  <header v-if="orderForm.discounts && orderForm.discounts.length !== 0">Хөнгөлөлт</header>
                  <ul class="transfer-product-list" v-if="orderForm.discounts && orderForm.discounts.length !== 0">
                    <div v-for="(discount) in orderForm.discounts" :key="discount.discount_id">
                      <el-row :gutter="10" class="mb20" style="padding: 10px" type="flex" align="middle">
                        <el-col :span="8">
                          <div>{{discount.discount_title_mon}}</div>
                          <div class="text-color-secondary" style="margin-top:5px; margin-bottom:5px">{{generateCouponType(discount.discount_type)}}</div>
                          <b v-if="discount.discount_type === 'PERCENTAGE'">Хөнгөлөлт - {{ discount.discount.saled_percentage }} %</b>
                          <b v-else-if="discount.discount_type === 'FIXED_AMOUNT'">Хөнгөлөлт - {{ generateCurrency(discount.discount.saled_price) }} ₮</b>
                        </el-col>
                        <el-col :span="11">
                          <div>{{ discount.discount_code }}</div>
                        </el-col>
                        <el-col :span="2" class="text-right">
                          <i @click="deleteDiscountFromOrder(discount)" class="el-icon-close cursor-pointer"></i>
                        </el-col>
                      </el-row>
                    </div>
                  </ul>
                </div>
                <div class="panel-item">
                  <header>Захиалгын дэлгэрэнгүй</header>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Захиалгын тэмдэглэл" class="mb20">
                        <el-input v-model="orderForm.orderNote" type="textarea" rows="3" placeholder="Тэмдэглэл бичих"></el-input>
                      </el-form-item>
                      <el-form-item label="Купон">
                        <el-row :gutter="10">
                          <el-col :span="16">
                            <el-input v-model="orderForm.discount_code" placeholder="Купон код оруулах"></el-input>
                          </el-col>
                          <el-col :span="8">
                            <el-button :disabled="isDisabledCoupon" :type="isDisabledCoupon ? 'disabled' : 'warning'" class="block" style="width:100px" :loading="isDiscountLoading" @click="setCoupon">Хадгалах</el-button>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-row :gutter="10" class="mb20">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Барааны нийт үнэ
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderForm.menu_total)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb20">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Хүргэлтийн төлбөр
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderForm.delivery_charge)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb20" v-if="orderForm.delivery_fee && orderForm.delivery_fee !== 0">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Бага дүнтэй захиалгын хураамж
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderForm.delivery_fee)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb20" v-if="orderForm.discount_total && orderForm.discount_total !== 0 && (orderForm.pay_total_no_discount - orderForm.pay_total ) !== 0">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Хөнгөлөлтгүй дүн
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderForm.pay_total_no_discount)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb20" v-if="orderForm.discount_total && orderForm.discount_total !== 0">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Хөнгөлөлтийн дүн
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(orderForm.discount_total)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            <strong> Төлөх дүн </strong>
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>
                            <strong>{{generateCurrency(orderForm.pay_total)}}</strong>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <el-col :span="activeStep === 0 ? 0 : 8">
              <div class="panel" v-if="activeStep > 0">
                <div class="panel-item">
                  <header>Хэрэглэгч</header>
                  <ul class="pl20">
                    <li><b>Утас: </b>{{ orderForm.phone }}</li>
                    <li><b>Email: </b>{{ orderForm.email }}</li>
                  </ul>
                </div>
              </div>
              <div class="panel" v-if="activeStep > 1">
                <div class="panel-item">
                  <header>Хүргэлтийн хаяг</header>
                  <ul class="pl20">
                    <li><b>Хаяг: </b>{{ orderForm.address.full_address }}</li>
                    <li><b>Орц: </b>{{ orderForm.address.orts }}</li>
                    <li><b>Хаалга: </b>{{ orderForm.address.haalga }}</li>
                    <li><b>Орцны код: </b>{{ orderForm.address.orts_code }}</li>
                    <li><b>Нэмэлт мэдээлэл: </b>{{ orderForm.address.desc }}</li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="text-right border-top pt20">
            <el-button type="default" @click="confirmLeaveForm = true"
              >Болих</el-button
            >
            <el-button v-if="activeStep > 0" type="warning" @click="prevStep" icon="el-icon-arrow-left">Өмнөх</el-button>
            <el-button v-if="activeStep < 2" :disabled="isButtonDisabled" :type="isButtonDisabled ? 'disabled' : 'primary'" :loading="isButtonLoading" @click="nextStep">Дараах <i class="el-icon-arrow-right el-icon-right"></i></el-button>
            <el-button v-if="activeStep===2" :disabled="isDisabledCart" :type="isDisabledCart ? 'disabled' : 'success'" :loading="isButtonLoading" @click="saveOrder('orderForm')">Захиалах</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!-- Product search -->
    <el-dialog
      title="Бүтээгдэхүүн сонгох"
      :visible.sync="visibleProductDialog"
      width="500"
    >
      <div>
        <el-input
          placeholder="Барааны нэр, SKU ..."
          v-model="searchText"
          @input="searchProduct"
        >
          <el-button slot="prepend" icon="el-icon-search" @click="searchProduct"></el-button>
        </el-input>
      </div>
      <div class="mt20" v-loading="productLoading">
        <ul class="transfer-product-list" v-if="products.length > 0">
          <li v-for="product in products" :key="product.productId">
            <el-row :gutter="10" type="flex" align="middle">
              <el-col :span="2">
                <div class="image-holder">
                  <img :src="product.image" alt="">
                </div>
              </el-col>
              <el-col :span="20">
                {{product.name_mon}}
              </el-col>
              <el-col :span="2" class="text-right">
                <el-checkbox v-model="product.checked" :disabled="isProductDisabled(product)" :indeterminate="product.isIndeterminate" @change="checkProduct(product)"></el-checkbox>
              </el-col>
            </el-row>
            <ul>
              <li v-for="variant in product.variants" :key="variant.variant_id">
                <el-row :gutter="20" type="flex" align="middle">
                  <el-col :span="16" :offset="2">
                    <div>
                      {{variant.variant_name}}
                    </div>
                    <div class="text-color-secondary">
                      {{variant.variant_sku}}
                    </div>
                  </el-col>
                  <el-col :span="2">
                    {{generateCurrency(variant.variant_sellPrice)}}
                  </el-col>
                  <el-col :span="2">
                    <div class="text-center">
                      {{variant.availableCount}}
                    </div>
                  </el-col>
                  <el-col :span="2" class="text-right">
                    <el-checkbox v-model="variant.checked" :disabled="variant.availableCount === 0" @change="checkVariant(product, variant)"></el-checkbox>
                  </el-col>
                </el-row>
              </li>
            </ul>
          </li>
        </ul>
        <div v-else class="text-center mtb30">
          <div class="text-color-placeholder">
            <p>
              Бараа байхгүй байна
              <router-link to="/add-product">
                бүтээгдэхүүн нэмэх
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleProductDialog = false">Cancel</el-button>
        <el-button type="success" @click="setOrderProducts">
          Add to Order
        </el-button>
      </span>
    </el-dialog>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/order">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <loader :active="isLoading" is-full-screen />
  </div>
</template>
<script>
import helper from '../../../helpers/helper'
import CustomHeader from '../../../components/common/customHeader'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Loader from '../../../components/common/loader'
import services from '../../../helpers/services'
export default {
  name: 'addOrder',
  components: {
    CustomHeader,
    Loader
  },
  data () {
    return {
      activeStep: 0,
      confirmLeaveForm: false,
      visibleProductDialog: false,
      productLoading: false,
      addressLoading: false,
      isButtonLoading: false,
      isDiscountLoading: false,
      addressQuery: '',
      backupCartData: [],
      orderForm: {
        address: {
          desc: '',
          full_address: '',
          haalga: '',
          orts: '',
          orts_code: ''
        },
        products: [],
        phone: '',
        email: '',
        discount_code: '',
        menu_total: 0,
        delivery_charge: 0,
        pay_total: 0,
        orderNote: ''
      },
      orderFormRules: {
        phone: [
          { required: true, message: 'Хэрэглэгчийн утасны дугаар оруулна уу', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Хэрэглэгчийн мэйл хаягийг оруулна уу', trigger: 'blur' },
          { type: 'email', message: 'Email формат буруу байна', trigger: ['blur', 'change'] }
        ],
        address: {
          full_address: [
            { required: true, message: 'Хэрэглэгчийн хаягийг сонгон уу', trigger: ['blur', 'change'] }
          ],
          orts: [
            { required: true, message: 'Орцны мэдээлэл оруулна уу', trigger: 'blur' }
          ],
          haalga: [
            { required: true, message: 'Хаалганы дугаар оруулна уу', trigger: 'blur' }
          ]
        }
      },
      searchText: '',
      products: [],
      userId: null,
      suggestedAddresses: [],
      isLoading: false
    }
  },
  created () {
    this.createBasket()
  },
  computed: {
    isButtonDisabled () {
      var isDisabled = false
      if (this.activeStep === 0 && this.isDisabledUser) {
        isDisabled = true
      } else if (this.activeStep === 1 && this.isDisabledAddress) {
        isDisabled = true
      }
      return isDisabled
    },
    isDisabledCart () {
      return this.orderForm.products.length === 0
    },
    isDisabledUser () {
      var isUserDisabled = false
      if (this.orderForm.phone.length < 6 || this.orderForm.phone.length > 10) {
        isUserDisabled = true
      }
      if (!this.isEmail(this.orderForm.email)) {
        isUserDisabled = true
      }
      return isUserDisabled
    },
    isDisabledCoupon () {
      return this.orderForm.discount_code === ''
    },
    isDisabledAddress () {
      var isAddressDisabled = false
      if (this.orderForm.address.haalga === '') {
        isAddressDisabled = true
      }
      if (this.orderForm.address.full_address === '') {
        isAddressDisabled = true
      }
      if (this.orderForm.address.orts === '') {
        isAddressDisabled = true
      }
      return isAddressDisabled
    }
  },
  methods: {
    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },
    generateCouponType (type) {
      var response = null
      switch (type) {
        case 'PERCENTAGE':
          response = 'Хувиар хямдрах'
          break
        case 'PRODUCT':
          response = 'Нэмэлт бүтээгдэхүүн'
          break
        case 'FIXED_AMOUNT':
          response = ' Мөнгөн дүнгээр хямдрах'
          break
        default:
          response = 'Хямдрал'
          break
      }
      return response
    },
    setCoupon () {
      this.isDiscountLoading = true
      const tempData = {
        discount_code: this.orderForm.discount_code
      }
      services.addCouponIntoBasket(this.userId, tempData).then((response) => {
        this.isDiscountLoading = false
        if (!response.error && response.data) {
          this.alertReporter('Амжилттай', 'Купон амжилттай ашиглагдлаа', 'success')
          this.setBasketData(response.data)
        }
      }).catch((error) => {
        this.isDiscountLoading = false
        this.alertReporter('Алдаа', error.data.message, 'error')
      })
    },
    deleteDiscountFromOrder (discount) {
      const tempData = {
        discount_code: discount.discount_code
      }
      services.removeCouponFromBasket(this.userId, tempData).then((response) => {
        if (!response.error && response.data) {
          this.setBasketData(response.data)
        }
      }).catch((error) => {
        this.alertReporter('Алдаа', error.data.message, 'error')
      })
    },
    prevStep () {
      this.activeStep--
    },
    nextStep () {
      if (this.activeStep === 0) {
        this.isButtonLoading = true
        const tempData = {
          email: this.orderForm.email,
          phone: this.orderForm.phone
        }
        services.addUserIntoBasket(this.userId, tempData).then((response) => {
          this.isButtonLoading = false
          if (!response.error && response.data) {
            this.setBasketData(response.data)
            this.activeStep++
          }
        })
      } else if (this.activeStep === 1) {
        this.isButtonLoading = true
        services.addAddressIntoBasket(this.userId, this.orderForm.address).then((response) => {
          this.isButtonLoading = false
          if (!response.error && response.data) {
            this.setBasketData(response.data)
            this.activeStep++
          }
        })
      }
    },
    isEmail (email) {
      // eslint-disable-next-line
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    async createFingerprint () {
      const fp = await FingerprintJS.load()
      const result = await fp.get().then((result) => { return result.visitorId })
      return result
    },
    async createBasket () {
      this.isLoading = true
      const fingerprint = await this.createFingerprint()
      this.userId = fingerprint
      services.createBasket(fingerprint).then((response) => {
        this.isLoading = false
        if (!response.error && response.data) {
          this.setBasketData(response.data)
        }
      })
    },
    setBasketData (data) {
      var address = data.address
      address.desc = address.desc ? address.desc : ''
      address.haalga = address.haalga ? address.haalga : ''
      address.orts = address.orts ? address.orts : ''
      address.orts_code = address.orts_code ? address.orts_code : ''
      data.discount_code = data.discount_code ? data.discount_code : ''
      data.address = address
      this.orderForm = data
      this.backupCartData = JSON.parse(JSON.stringify(data.products))
    },
    onChangeAddress (data) {
      this.suggestedAddresses.forEach((element) => {
        if (element.full_address === data) {
          element.bair_name = element.bairname
          element.un_official_address = element.bairnote
          element.location = `${element.latitude}, ${element.longitude}`
          element.user_lat = element.latitude
          element.user_lon = element.longitude
          element.desc = ''
          element.haalga = ''
          element.orts = ''
          element.orts_code = ''
          this.orderForm.address = JSON.parse(JSON.stringify(element))
        }
      })
    },
    changeCount () {
      services.setItemsToBasket(this.userId, this.setFinalBasketData()).then((response) => {
        if (!response.error && response.data) {
          this.setBasketData(response.data)
        }
      })
    },
    saveOrder (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isLoading = true
          services.createOrder(this.userId).then((response) => {
            this.isLoading = false
            if (response && !response.error && response.id) {
              this.alertReporter('Амжилттай', 'Захиалга амжилттай үүслээ', 'success')
              setTimeout(() => {
                this.$router.go(-1)
              }, 1000)
            } else {
              this.alertReporter('Уучлаарай', response.message, 'error')
            }
          }).catch((error) => {
            this.isLoading = false
            this.alertReporter('Уучлаарай', error.message, 'error')
          })
        } else {
          return false
        }
      })
    },
    setFinalBasketData () {
      var newArray = []
      this.orderForm.products.forEach(element => {
        this.backupCartData.forEach(oldElement => {
          var newProduct = JSON.parse(JSON.stringify(element))
          var oldProduct = JSON.parse(JSON.stringify(oldElement))
          if (newProduct.variant_id === oldProduct.variant_id && newProduct.item_count !== oldProduct.item_count) {
            newProduct.item_count = newProduct.item_count - oldProduct.item_count
            newArray.push(newProduct)
          }
        })
      })
      return newArray
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },
    isProductDisabled (product) {
      var isCheckable = false
      if (product.sell_check) {
        isCheckable = true
      } else {
        var count = 0
        product.variants.forEach(variant => {
          count = count + variant.availableCount
        })
        isCheckable = count === 0
      }
      return isCheckable
    },
    checkProduct (product) {
      product.variants.forEach(variant => {
        var isCheckable = product.checked
        if (variant.sell_check) {
          isCheckable = true
        } else {
          if (variant.availableCount === 0) {
            isCheckable = false
          }
        }
        variant.checked = isCheckable
      })
    },
    checkVariant (product, variant) {
      let checkedCount = 0
      product.variants.forEach(variant => {
        if (variant.checked) {
          checkedCount = checkedCount + 1
        }
      })
      product.checked = checkedCount === product.variants.length
      product.isIndeterminate = checkedCount > 0 && checkedCount < product.variants.length
    },
    deleteVariantFromOrder (product) {
      const tempData = {
        product_id: product.product_id,
        variant_id: product.variant_id
      }
      services.removeItemFromBasket(this.userId, tempData).then((response) => {
        if (!response.error && response.data) {
          this.setBasketData(response.data)
        }
      })
    },
    generateCartItem (tempProducts) {
      var tempItems = []
      tempProducts.forEach(element => {
        element.variants.forEach(secondElement => {
          const orderItem = {
            brand_id: element.brand_id,
            brand_name: element.brand_name,
            item_count: 1,
            menu_price: secondElement.variant_sellPrice,
            nosale_price: secondElement.variant_sellPrice,
            product_id: element.productId,
            product_name: element.name_mon,
            sku: element.sku,
            supplier_id: element.supplier_id,
            supplier_name: element.supplier_name,
            variant_id: secondElement.variant_id,
            variant_name: secondElement.variant_name,
            sell_check: element.sell_check !== undefined ? element.sell_check : false,
            availableCount: secondElement.availableCount
          }
          tempItems.push(orderItem)
        })
      })
      return tempItems
    },
    setOrderProducts () {
      const tempProducts = []
      this.products.forEach(product => {
        const variants = [...product.variants.filter(variant => variant.checked)]
        if (variants.length > 0) {
          product.variants = variants
          tempProducts.push(product)
        }
      })
      if (tempProducts.length > 0) {
        const orderItems = this.generateCartItem(tempProducts)
        services.setItemsToBasket(this.userId, orderItems).then((response) => {
          if (!response.error && response.data) {
            this.setBasketData(response.data)
          }
        })
        this.visibleProductDialog = false
      } else {
        this.$notify.error({
          title: 'Oops',
          position: 'top-right',
          message: 'Бүтээгдэхүүн сонгоно уу',
          duration: 2000
        })
      }
    },
    searchProduct () {
      if (this.searchText.length > 2) {
        this.productLoading = true
        this.visibleProductDialog = true
        services.getProductsWithVariant(this.searchText).then(response => {
          if (Array.isArray(response) && response.length > 0) {
            response.forEach(product => {
              const property = {
                checked: false,
                isIndeterminate: false
              }
              Object.assign(product, property, product)
              product.variants.forEach(variant => {
                const property = {
                  checked: false,
                  item_count: 1
                }
                Object.assign(variant, property, variant)
              })
            })
            this.products = response
          }
          this.productLoading = false
        })
      }
    },
    filterAddress (val) {
      if (val.length > 1) {
        services.searchAddress(val).then(response => {
          if (Array.isArray(response) && response.length > 0) {
            this.suggestedAddresses = response
          } else {
            this.suggestedAddresses = []
          }
        })
      }
    }
  }
}
</script>
